import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Transition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

// Components
import ToggleArrow from '../../ToggleArrow';

// Icons
import CheeseBorderIcon from '../../../icons/cheese-border.svg';
import CheckIcon from '../../../icons/check.svg';

// Selectors
import { isThemeHalloween, isThemeNewYear } from '../../../state/modules/city/selectors';

// Assets
import stuffedCrustNone from './assets/stuffedCrustNone.png';
import stuffedCrustCheese from './assets/stuffedCrustCheese.png';
import stuffedCrustSausage from './assets/stuffedCrustSausage.png';
import stuffedCrustPepperoni from './assets/stuffedCrustPep.png';
import stuffedCrustSausageHalal from './assets/stuffedCrustSausageHalal.jpg';

// Styles
import styles from './BorderToggle.styl';

const cx = classNames.bind(styles);

export default function CheeseBorderToggle(props) {
  const {
    selectStuffedCrust,
    stuffedCrustsAvailable,
    className,
    activeStuffedCrust,
    isBottom,
    specialStylesMode,
  } = props;

  const availableStuffedCrusts = [...stuffedCrustsAvailable];
  const isHalloweenMode = useSelector(isThemeHalloween);
  const isNewYearMode = useSelector(isThemeNewYear);
  const city = useSelector(state => state.city.userCity);
  const isHalal = !!city?.is_halal;

  const stuffedCrustImages = {
    none: stuffedCrustNone,
    cheese: stuffedCrustCheese,
    sausage: !isHalal ? stuffedCrustSausage : stuffedCrustSausageHalal,
    peperoni: stuffedCrustPepperoni,
  };

  const [isOpen, setIsOpen] = useState(false);

  const sortCrusts = (stuffed_crust, stuffed_crust_second) => {
    if (stuffed_crust === 'none') {
      return 1;
    }

    if (stuffed_crust_second === 'none') {
      return -1;
    }

    if (stuffed_crust === 'cheese' && stuffed_crust_second === 'sausage') {
      return 1;
    }

    if (stuffed_crust === 'sausage' && stuffed_crust_second === 'cheese') {
      return -1;
    }
    if (stuffed_crust === 'cheese' && stuffed_crust_second === 'peperoni') {
      return 1;
    }
    if (stuffed_crust === 'sausage' && stuffed_crust_second === 'peperoni') {
      return 1;
    }
    if (stuffed_crust === 'peperoni' && stuffed_crust_second === 'cheese') {
      return -1;
    }
    if (stuffed_crust === 'peperoni' && stuffed_crust_second === 'sausage') {
      return -1;
    }
    return 0;
  };

  const onToggle = () => {
    setIsOpen(prev => !prev);
  };

  const onClick = value => {
    selectStuffedCrust(value);
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      onToggle();
    }
  };

  const onBlur = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={cx('BorderToggle', className, {
        BorderToggle_bottom: isBottom,
        BorderToggle__halloween: isHalloweenMode && specialStylesMode,
        'BorderToggle__new-year': isNewYearMode && specialStylesMode,
      })}
      onBlur={onBlur}
    >
      <div
        className={cx(`BorderToggle__toggle`, {
          BorderToggle__toggle_active: activeStuffedCrust !== 'none',
        })}
        onClick={onToggle}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex={0}
      >
        <CheeseBorderIcon width={43} height={21} />
        <ToggleArrow className={cx('BorderToggle__toggle-arrow')} isOpen={isOpen} />
      </div>
      <Transition in={isOpen} timeout={{ enter: 0, exit: 175 }} mountOnEnter unmountOnExit>
        {state => (
          <div className={cx('BorderToggle__body', `BorderToggle__body_${state}`)}>
            {availableStuffedCrusts.sort(sortCrusts).map(type => (
              <button
                key={type}
                className={cx('BorderToggle__item', {
                  BorderToggle__item_active: activeStuffedCrust === type,
                })}
                name={type}
                type="button"
                onClick={() => onClick(type)}
              >
                <img
                  className={cx('BorderToggle__img')}
                  src={stuffedCrustImages[type]}
                  alt="stuffedCrust"
                />
                <div className={cx('BorderToggle__text')}>
                  <FormattedMessage id={`pizza.stuffedCrust.${type}`} />
                </div>
                <div className={cx('BorderToggle__icon-wrapper')}>
                  <CheckIcon className={cx('BorderToggle__icon')} />
                </div>
              </button>
            ))}
          </div>
        )}
      </Transition>
    </div>
  );
}

CheeseBorderToggle.defaultProps = {
  className: '',
  stuffedCrustsAvailable: [],
  isBottom: false,
};

CheeseBorderToggle.propTypes = {
  className: PropTypes.string,
  stuffedCrustsAvailable: PropTypes.array,
  activeStuffedCrust: PropTypes.string.isRequired,
  selectStuffedCrust: PropTypes.func.isRequired,
  isBottom: PropTypes.bool,
};
